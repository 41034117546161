import useLocalStorage from "../hooks/useLocalStorage";
import generateUUID from "../utilities/generateUUID";
import React, {createContext, useState} from "react";

let UserPokedexContext = createContext(null);

function UserPokedexContextProvider(props) {
    const [userPokedex, setUserPokedex] = useLocalStorage("tracker-user-pokedex", {});
    const [activeParty, setActiveParty] = useLocalStorage("tracker-active-party", []);
    const [boxPokemon, setBoxPokemon] = useLocalStorage("tracker-box-pokemon", []);
    const [pokemonBeingDragged, setPokemonBeingDragged] = useState("");
    const [pokemonDraggedOver, setPokemonDraggedOver] = useState("");
    const [partyDraggedOver, setPartyDraggedOver] = useState(false);
    const [boxDraggedOver, setBoxDraggedOver] = useState(false);

    const partyFull = activeParty.length === 6;
    const party = activeParty.length < 6 ? [...activeParty, ...Array(6 - activeParty.length)] : activeParty;

    // userPokedex is an object with uuid keys
    // used to uniquely id caught pokemon
    // those keys reference objects that
    // represent pokemon, which have attributes:
    //   pokemon: string, the pokemon name
    //   nickname: string
    //   isShiny: bool
    //   isHolding: string, item being held

    const addPokemonToPokedex = (pokemon, destination) => {
        if (partyFull && destination === "party") {
            alert("Party full, please select Box storage.");
            return;
        }
        const uuid = generateUUID();
        let newPokedex = {...userPokedex};
        newPokedex[uuid] = pokemon;
        setUserPokedex(newPokedex);
        if (!destination && !partyFull) destination = "party";
        if (destination === "party") {
            setActiveParty([...activeParty, uuid]);
        } else {
            setBoxPokemon([...boxPokemon, uuid]);
        }
    };

    const deletePokemon = uuid => {
        if (!window.confirm('for real?')) {
            return;
        }

        let newPokedex = {...userPokedex};
        delete newPokedex[uuid];
        setUserPokedex({...newPokedex});

        let party = [...activeParty];
        let box = [...boxPokemon];
        let boxIndex = box.indexOf(uuid);
        let partyIndex = party.indexOf(uuid)
        if (boxIndex !== -1) {
            box.splice(boxIndex, 1);
            setBoxPokemon([...box])
        }
        if (partyIndex !== -1) {
            party.splice(partyIndex, 1);
            setActiveParty([...party]);
        }
    };

    const pokemonIsInParty = uuid => {
        return activeParty.includes(uuid);
    };

    const moveFromBoxToParty = uuid => {
        let party = [...activeParty];
        let box = [...boxPokemon];
        let index = box.indexOf(uuid);
        party.push(uuid);
        box.splice(index, 1);
        setActiveParty([...party]);
        setBoxPokemon([...box]);
    };

    const moveFromPartyToBox = uuid => {
        let party = [...activeParty];
        let box = [...boxPokemon];
        let index = party.indexOf(uuid);
        party.splice(index, 1);
        box.push(uuid);
        setActiveParty(party);
        setBoxPokemon(box)
    };

    const moveToEndOfParty = uuid => {
        let party = [...activeParty];
        party.splice(party.indexOf(uuid), 1);
        party.push(uuid);
        setActiveParty([...party]);
    };

    const moveToEndOfBox = uuid => {
        let box = [...boxPokemon];
        box.splice(box.indexOf(uuid), 1);
        box.push(uuid);
        setBoxPokemon([...box]);
    };

    const swapPartyAndBox = (inPartyPkmn, inBoxPkmn) => {
        let party = [...activeParty];
        let box = [...boxPokemon];
        const inPartyIndex = party.indexOf(inPartyPkmn);
        const inBoxIndex = box.indexOf(inBoxPkmn);
        party[inPartyIndex] = inBoxPkmn;
        box[inBoxIndex] = inPartyPkmn;
        setActiveParty([...party]);
        setBoxPokemon([...box]);
    };

    const swapPartyPokemon = (source, target) => {
        let party = [...activeParty];
        const sourceIndex = party.indexOf(source);
        const targetIndex = party.indexOf(target);
        party[sourceIndex] = target;
        party[targetIndex] = source;
        setActiveParty([...party]);
    };

    const swapBoxPokemon = (target, source) => {
        let box = [...boxPokemon];
        const sourceIndex = box.indexOf(source);
        const targetIndex = box.indexOf(target);
        box[sourceIndex] = target;
        box[targetIndex] = source;
        setBoxPokemon([...box]);
    };

    const performDragAndDropUpdates = () => {
        const source = pokemonBeingDragged;
        const target = pokemonDraggedOver;
        const draggedIsInParty = pokemonIsInParty(source);
        // we're swapping positions
        if (pokemonDraggedOver) {
            const targetIsInParty = pokemonIsInParty(target);
            if (draggedIsInParty && targetIsInParty) {
                swapPartyPokemon(source, target);
            } else if (draggedIsInParty && !targetIsInParty) {
                // swap party and box
                swapPartyAndBox(source, target);
            } else if (!draggedIsInParty && targetIsInParty) {
                swapPartyAndBox(target, source)
            } else if (!draggedIsInParty && !targetIsInParty) {
                swapBoxPokemon(target, source);
            }
        } else {
            // not dragged over another pokemon
            if (draggedIsInParty && boxDraggedOver) {
                moveFromPartyToBox(source);
            } else if (draggedIsInParty && partyDraggedOver) {
                moveToEndOfParty(source);
            } else if (!draggedIsInParty && partyDraggedOver) {
                if (partyFull) {
                    alert("Party is full. Drag over Pokemon in party, or move Pokemon out of party.");
                } else {
                    moveFromBoxToParty(source);
                }
            } else if (!draggedIsInParty && boxDraggedOver) {
                moveToEndOfBox(source);
            }
        }
    };

    const setPokemonAttribute = (uuid, attributes) => {
        let pokedex = {...userPokedex};
        let pokemon = pokedex[uuid];
        pokedex[uuid] = {...pokemon, ...attributes};
        setUserPokedex({...pokedex});
    };

    const setPokemonLevel = (uuid, level) => {
        setPokemonAttribute(uuid, {"level": level});
    };

    const setPokemonIsShiny = (uuid, isShiny) => {
        setPokemonAttribute(uuid, {"isShiny": isShiny});
    };

    const setPokemonIsDead = (uuid, isDead) => {
        setPokemonAttribute(uuid, {"isDead": isDead});
    };

    const setPokemonNickname = (uuid, nickname) => {
        setPokemonAttribute(uuid, {"nickname": nickname});
    };

    const setPokemonIsHolding = (uuid, item) => {
        setPokemonAttribute(uuid, {"isHolding": item});
    };

    const setPokemonPokemon = (uuid, pokemon) => {
        setPokemonAttribute(uuid, {"pokemon": pokemon});
    };

    const setPokemonSpriteSize = (uuid, size) => {
        setPokemonAttribute(uuid, {"spriteSize": size});
    };

    const resetPokedex = () => {
        setUserPokedex({});
        setActiveParty([]);
        setBoxPokemon([]);
    };

    const value = {
        party,
        partyFull,
        boxPokemon,
        userPokedex,
        resetPokedex,
        activeParty,
        setBoxPokemon,
        deletePokemon,
        setActiveParty,
        setUserPokedex,
        boxDraggedOver,
        setPokemonLevel,
        pokemonIsInParty,
        partyDraggedOver,
        setPokemonIsDead,
        setPokemonPokemon,
        setPokemonIsShiny,
        setBoxDraggedOver,
        pokemonDraggedOver,
        setPokemonNickname,
        moveFromPartyToBox,
        setPokemonIsHolding,
        setPartyDraggedOver,
        addPokemonToPokedex,
        pokemonBeingDragged,
        setPokemonSpriteSize,
        setPokemonDraggedOver,
        setPokemonBeingDragged,
        performDragAndDropUpdates,
    };

    return (
        <UserPokedexContext.Provider value={value}>
            {props.children}
        </UserPokedexContext.Provider>
    );
}

export { UserPokedexContext, UserPokedexContextProvider };
