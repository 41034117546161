import useLocalStorage from "../hooks/useLocalStorage";
import React, {createContext} from "react";

let SaveContext = createContext(null);

function SaveContextProvider(props) {
    const [saveFileNames, setSaveFileNames] = useLocalStorage("tracker-save-files", []);
    const [trackerActiveFileName, setTrackerActiveFileName] = useLocalStorage("tracker-active-file-name", "");

    const addSaveFileName = filename => {
        setSaveFileNames([...saveFileNames, filename]);
    };

    const deleteSavedSaveFile = filename => {
        let newFileNames = saveFileNames.filter(name => name !== filename);
        setSaveFileNames([...newFileNames]);
        deleteSaveFile(filename);
        if (filename === trackerActiveFileName) {
            setTrackerActiveFileName("");
        }
    };

    const fileNameAlreadyExists = filename => {
        return saveFileNames.includes(filename);
    };

    const saveFile = (filename, data) => {
        localStorage.setItem(`save-file-${filename}`, JSON.stringify(data));
    };

    const deleteSaveFile = filename => {
        localStorage.removeItem(`save-file-${filename}`);
    };

    const saveCurrentState = state => {
        saveFile(trackerActiveFileName, state);
    }

    const getSaveFile = filename => {
        const storage = localStorage.getItem(`save-file-${filename}`);
        return JSON.parse(storage);
    };

    const value = {
        saveFile,
        getSaveFile,
        saveFileNames,
        deleteSaveFile,
        addSaveFileName,
        setSaveFileNames,
        saveCurrentState,
        deleteSavedSaveFile,
        fileNameAlreadyExists,
        trackerActiveFileName,
        setTrackerActiveFileName,
    };

    return (
        <SaveContext.Provider value={value}>
            {props.children}
        </SaveContext.Provider>
    );
}

export { SaveContext, SaveContextProvider };