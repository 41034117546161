import useTrackerContext from "../../hooks/useTrackerContext";
import holdItems from "../../data/hold-items";
import React, {useState} from "react";

const PokemonEditor = ({ uuid }) => {

    const [showEditFields, setShowEditFields] = useState(false);

    const {
        userPokedex,
        deletePokemon,
        setPokemonLevel,
        availablePokemon,
        setPokemonIsDead,
        setPokemonPokemon,
        setPokemonIsShiny,
        moveFromPartyToBox,
        setPokemonNickname,
        setPokemonIsHolding,
        setPokemonSpriteSize,
    } = useTrackerContext();

    const pokemon = userPokedex[uuid];

    return (
        <div className="editor-entry">
            <div className="editor-name">
                {pokemon.nickname ? pokemon.nickname : pokemon.pokemon}
                <span className="show-fields" onClick={() => setShowEditFields(!showEditFields)}>
                    {showEditFields ? "Close" : "Edit"}
                </span>
            </div>
            {showEditFields && <div className="editor-fields">
                <div className="editor-field">
                    <label htmlFor="pokemon-select">Pokemon</label>
                    <select
                        name="pokemon-select"
                        onChange={e => setPokemonPokemon(uuid, e.target.value)}
                        value={pokemon.pokemon}
                    >
                        <option value="">Select Pokemon</option>
                        {Object.entries(availablePokemon).map(([gen_num, gen_pkmn], i) => {
                            return <optgroup key={i} label={`Gen ${gen_num}`}>
                                {gen_pkmn.map((pkmn, j) => {
                                    return <option key={j} value={pkmn}>
                                        {pkmn}
                                    </option>
                                })}
                            </optgroup>
                        })}
                    </select>
                </div>
                <div className="editor-field">
                    <label htmlFor="level-select">Level</label>
                    <input
                        name="level-select"
                        onChange={e => setPokemonLevel(uuid, parseInt(e.target.value))}
                        value={pokemon.level}
                        type="number"
                        min={1}
                        max={100}
                    />
                </div>
                <div className="editor-field">
                    <label htmlFor="nickname">Nickname</label>
                    <input
                        name="nickname"
                        onChange={e => setPokemonNickname(uuid, e.target.value)}
                        value={pokemon.nickname}
                        type="text"
                        maxLength={10}
                    />
                </div>
                <div className="editor-field is-shiny">
                    <label htmlFor="is-shiny">Shiny</label>
                    <input
                        name="is-shiny"
                        type="checkbox"
                        checked={pokemon.isShiny}
                        onChange={e => setPokemonIsShiny(uuid, e.target.checked)}
                    />
                </div>
                <div className="editor-field">
                    <label htmlFor="item-held">Item Holding</label>
                    <select
                        name="item-held"
                        onChange={e => setPokemonIsHolding(uuid, e.target.value)}
                        value={pokemon.isHolding}
                    >
                        <option value="">Select Item</option>
                        {holdItems.map((item, i) => {
                            return <option key={i} value={item}>
                                {item}
                            </option>
                        })}
                    </select>
                </div>
                <div className="editor-field">
                    <label htmlFor="sprite-size">Sprite Size</label>
                    <input
                      name="sprite-size"
                      onChange={e => setPokemonSpriteSize(uuid, e.target.value)}
                      value={pokemon.spriteSize === undefined ? 1 : pokemon.spriteSize}
                      type="number"
                      min={0.00}
                      max={2.00}
                      step={0.05}
                    />
                </div>
                <button
                  className="confirm-button"
                  onClick={() => moveFromPartyToBox(uuid)}
                >
                    Box
                </button>
                <button
                  className="danger-button"
                  onClick={() => setPokemonIsDead(uuid, !pokemon.isDead)}
                >
                    Dead
                </button>
                <button
                  className="danger-button"
                  onClick={() => deletePokemon(uuid)}
                >
                    Delete
                </button>
            </div>}
        </div>
    )
};

export default PokemonEditor;
