
// take in hex color value,
// return it at {percent} brightness
// caps hex values at 0 and 255
const changeHexBrightness = (hex, percent = 100) => {
    if (!hex.match(/^#?[0-9A-Fa-f]{3}([0-9A-Fa-f]{3})?$/)) {
        throw Error("Must be a 3 or 6 digit hex representation of a color");
    }
    let startsWithHash = hex.startsWith("#");
    if (startsWithHash) {
        hex = hex.replace("#", "");
    }
    if (hex.length === 3) {
        hex = hex.split("").map(char => {
            return `${char}${char}`
        }).join("").toLowerCase();
    } else {
        hex = hex.toLowerCase();
    }

    let rgbChunks = hex.match(/.{2}/g).map(chunk => {
        let chunkInt = parseInt(chunk, 16);
        let intPercent = Math.floor(chunkInt * (percent / 100));
        if (intPercent < 0) intPercent = 0;
        if (intPercent > 255) intPercent = 255;
        return intPercent.toString(16).padStart(2, "0");
    });

    return `${startsWithHash ? "#" : ""}${rgbChunks.join("")}`;
};

export default changeHexBrightness;
