import useTrackerContext from "../../hooks/useTrackerContext";
import "./sizing-config.scss";
import React from "react";

const SizingConfig = () => {

    const {
        boxSpriteSizing,
        partyPokeballSizing,
        setPartyPokeballSizing,
        partySpriteSizing,
        setBoxSpriteSizing,
        setPartySpriteSizing,
        partySlotSizing,
        setPartySlotSizing,
        boxSlotSizing,
        setBoxSlotSizing,
        partySlotSpacing,
        setPartySlotSpacing,
        backgroundPokeballSizing,
        setBackgroundPokeballSizing,
        partyItemSizing,
        setPartyItemSizing,
        boxItemSizing,
        setBoxItemSizing,
        boxSlotSpacing,
        setBoxSlotSpacing,
        partyTextSizing,
        partyWidthSizing,
        setPartyWidthSizing,
        setPartyTextSizing,
        boxTextSizing,
        setBoxTextSizing,
        boxHeight,
        setBoxHeight,
    } = useTrackerContext();

    const configNumberInputs = [
        {
            "text": "Party Container Width",
            "value": partyWidthSizing,
            "func": setPartyWidthSizing,
        },
        {
            "text": "Background Pokeball Size",
            "value": backgroundPokeballSizing,
            "func": setBackgroundPokeballSizing,
        },
        {
            "text": "Party Slot Size",
            "value": partySlotSizing,
            "func": setPartySlotSizing,
        },
        {
            "text": "Party Slot Spacing",
            "value": partySlotSpacing,
            "func": setPartySlotSpacing,
        },
        {
            "text": "Party Pokeball Size",
            "value": partyPokeballSizing,
            "func": setPartyPokeballSizing,
        },
        {
            "text": "Party Sprite Size",
            "value": partySpriteSizing,
            "func": setPartySpriteSizing,
        },
        {
            "text": "Party Item Size",
            "value": partyItemSizing,
            "func": setPartyItemSizing,
        },
        {
            "text": "Party Text Size",
            "value": partyTextSizing,
            "func": setPartyTextSizing,
        },
        {
            "text": "Box Slot Size",
            "value": boxSlotSizing,
            "func": setBoxSlotSizing,
        },
        {
            "text": "Box Slot Spacing",
            "value": boxSlotSpacing,
            "func": setBoxSlotSpacing,
        },
        {
            "text": "Box Sprite Size",
            "value": boxSpriteSizing,
            "func": setBoxSpriteSizing,
        },
        {
            "text": "Box Item Size",
            "value": boxItemSizing,
            "func": setBoxItemSizing,
        },
        {
            "text": "Box Text Size",
            "value": boxTextSizing,
            "func": setBoxTextSizing,
        },
        {
            "text": "Box Height",
            "value": boxHeight,
            "func": setBoxHeight,
        },
    ];

    return (
        <div className="config">
            {
                configNumberInputs.map((option, i) => {
                    return <div className="sizing-option" key={i}>
                        <label>{option.text}</label>
                        <input
                            type="number"
                            value={option.value}
                            onChange={e => option.func(e.target.value)}
                            step="1"
                            min="1"
                        />
                    </div>
                })
            }
        </div>
    )
};

export default SizingConfig;
