import "../data/pokemon";
import pokemon from "../data/pokemon";

const getGenOfPokemon = name => {
    for (let gen of Object.keys(pokemon)) {
        if (pokemon[gen].includes(name)) {
            return gen;
        }
    }
    return 0;
};

export default getGenOfPokemon;
