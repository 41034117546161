import PokemonEditor from "../../components/pokemon/PokemonEditor";
import useTrackerContext from "../../hooks/useTrackerContext";
import "./party-editor.scss";
import React from "react";

const PartyEditor = () => {

    const {
        activeParty,
    } = useTrackerContext();

    return (
        <div
            className="party-editor"
        >
            {
                activeParty.length ? activeParty.map((uuid, i) => {
                    return <PokemonEditor key={i} uuid={uuid} />
                }) : <span>Add Pokemon to your party to edit</span>
            }
        </div>
    )
};

export default PartyEditor;
