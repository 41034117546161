import useTrackerContext from "../../hooks/useTrackerContext";
import Pokemon from "./Pokemon";
import React from "react";

const BoxPokemon = ({ uuid }) => {

    const {
        boxSlotSizing,
        boxSlotSpacing,
        sizingBeingEdited,
    } = useTrackerContext();

    return (
        <div
            className="pokemon-container"
            style={{
                margin: `${boxSlotSpacing}px`,
            }}
        >
            <div
                className={["pokemon-container-wrapper", sizingBeingEdited ? "show-borders" : "hide-borders"].join(" ")}
                style={{
                    width: `${boxSlotSizing}px`,
                    height: `${boxSlotSizing}px`,
                }}
            >
                <Pokemon uuid={uuid} />
            </div>
        </div>
    )
};

export default BoxPokemon;
