import {UserPokedexContextProvider} from "./context/UserPokedexContext";
import "./pokemon-party-tracker.scss";
import React from "react";
import {SaveContextProvider} from "./context/SaveContext";
import {ConfigContextProvider} from "./context/ConfigContext";
import TrackerLayout from "./layout/TrackerLayout";

const PokemonPartyTracker = () => {
    return (
        <div>
            <SaveContextProvider>
                <ConfigContextProvider>
                    <UserPokedexContextProvider>
                        <TrackerLayout />
                    </UserPokedexContextProvider>
                </ConfigContextProvider>
            </SaveContextProvider>
        </div>
    )
};

export default PokemonPartyTracker;
