import useLocalStorage from "../hooks/useLocalStorage";
import React, {createContext, useState, useEffect} from "react";
import defaultConfig from "./defaultConfig";

let ConfigContext = createContext(null);

function ConfigContextProvider(props) {
    const [backgroundColor, setBackgroundColor] = useLocalStorage("tracker-background-color", defaultConfig.background);
    const [gen, setGen] = useLocalStorage("tracker-current-gen", defaultConfig.gen);
    const [partyWidthSizing, setPartyWidthSizing] = useLocalStorage("party-container-sizing", defaultConfig.partyContainer);
    const [backgroundPokeballSizing, setBackgroundPokeballSizing] = useLocalStorage("background-pokeball-sizing", defaultConfig.backgroundPokeball);
    const [partySlotSizing, setPartySlotSizing] = useLocalStorage("party-slot-sizing", defaultConfig.partySlot);
    const [partySlotSpacing, setPartySlotSpacing] = useLocalStorage("party-slot-spacing", defaultConfig.partySpacing);
    const [partyPokeballSizing, setPartyPokeballSizing] = useLocalStorage("party-pokeball-sizing", defaultConfig.partyPokeball);
    const [partySpriteSizing, setPartySpriteSizing] = useLocalStorage("party-sprite-sizing", defaultConfig.partySprite);
    const [partyItemSizing, setPartyItemSizing] = useLocalStorage("party-item-sizing", defaultConfig.partyItem);
    const [partyTextSizing, setPartyTextSizing] = useLocalStorage("party-text-sizing", defaultConfig.partyText);
    const [boxSlotSizing, setBoxSlotSizing] = useLocalStorage("box-slot-sizing", defaultConfig.boxSlot)
    const [boxSlotSpacing, setBoxSlotSpacing] = useLocalStorage("box-slot-spacing", defaultConfig.boxSpacing);
    const [boxSpriteSizing, setBoxSpriteSizing] = useLocalStorage("box-sprite-sizing", defaultConfig.boxSprite);
    const [boxItemSizing, setBoxItemSizing] = useLocalStorage("box-item-sizing", defaultConfig.boxItem);
    const [boxTextSizing, setBoxTextSizing] = useLocalStorage("box-text-sizing", defaultConfig.boxText);
    const [boxHeight, setBoxHeight] = useLocalStorage("box-height", defaultConfig.boxHeight);
    const [boxRowWraps, setBoxRowWraps] = useLocalStorage("box-row-wrap", defaultConfig.boxRowWrap);
    const [showBigPokeball, setShowBigPokeball] = useLocalStorage("show-big-pokeball", defaultConfig.showBigPokeball);
    const [showSmallPokeballs, setShowSmallPokeballs] = useLocalStorage("show-small-pokeballs", defaultConfig.showSmallPokeballs);

    const [sizingBeingEdited, setSizingBeingEdited] = useState(false);
    const [selectingGen, setSelectingGen] = useState(false);

    useEffect(() => {
        document.documentElement.style.setProperty("--background", backgroundColor);
    }, [backgroundColor]);

    useEffect(() => {
        document.documentElement.style.setProperty("--box-height", `${boxHeight}px`);
    }, [boxHeight]);

    const resetConfigs = () => {
        setPartyWidthSizing(defaultConfig.partyContainer);
        setBackgroundPokeballSizing(defaultConfig.backgroundPokeball);
        setPartySlotSizing(defaultConfig.partySlot);
        setPartySlotSpacing(defaultConfig.partySpacing);
        setPartyPokeballSizing(defaultConfig.partyPokeball);
        setPartySpriteSizing(defaultConfig.partySprite);
        setPartyItemSizing(defaultConfig.partyItem);
        setPartyTextSizing(defaultConfig.partyText);
        setBoxSlotSizing(defaultConfig.boxSlot);
        setBoxSlotSpacing(defaultConfig.boxSpacing);
        setBoxSpriteSizing(defaultConfig.boxSprite);
        setBoxItemSizing(defaultConfig.boxItem);
        setBoxTextSizing(defaultConfig.boxText);
        setGen(defaultConfig.gen);
        setBackgroundColor(defaultConfig.background);
        setBoxHeight(defaultConfig.boxHeight);
        setBoxRowWraps(defaultConfig.boxRowWrap);
        setShowBigPokeball(defaultConfig.showBigPokeball);
        setShowSmallPokeballs(defaultConfig.showSmallPokeballs);
    };

    const value = {
        gen,
        setGen,
        boxHeight,
        boxRowWraps,
        setBoxHeight,
        resetConfigs,
        showBigPokeball,
        setShowBigPokeball,
        showSmallPokeballs,
        setShowSmallPokeballs,
        setBoxRowWraps,
        partyTextSizing,
        setPartyTextSizing,
        boxTextSizing,
        setBoxTextSizing,
        selectingGen,
        boxSlotSizing,
        setBoxSlotSizing,
        boxSpriteSizing,
        setSelectingGen,
        backgroundColor,
        partyItemSizing,
        setPartyItemSizing,
        sizingBeingEdited,
        setSizingBeingEdited,
        boxItemSizing,
        setBoxItemSizing,
        partySlotSizing,
        partyWidthSizing,
        setPartyWidthSizing,
        setPartySlotSizing,
        backgroundPokeballSizing,
        setBackgroundPokeballSizing,
        partyPokeballSizing,
        setBoxSpriteSizing,
        setBackgroundColor,
        partySlotSpacing,
        setPartySlotSpacing,
        boxSlotSpacing,
        setBoxSlotSpacing,
        partySpriteSizing,
        setPartySpriteSizing,
        setPartyPokeballSizing,
    };

    return (
        <ConfigContext.Provider value={value}>
            {props.children}
        </ConfigContext.Provider>
    );
}

export { ConfigContext, ConfigContextProvider };
