import changeHexBrightness from "../utilities/changeHexBrightness";
import useTrackerContext from "../hooks/useTrackerContext";
import ConfigSection from "./sidebar/ConfigSection";
import PokemonAdder from "./sidebar/PokemonAdder";
import GenSelector from "./sidebar/GenSelector";
import PartyEditor from "./sidebar/PartyEditor";
import React, {useState,useEffect} from "react";
import "./sidebar-section.scss";

const SidebarSection = () => {

    const {
        gen,
        backgroundColor,
        setSizingBeingEdited,
    } = useTrackerContext();

    const [sectionToShow, setSectionToShow] = useState(gen ? "party-editor" : "gen-selector");

    useEffect(() => {
        if (gen && sectionToShow === "gen-selector") {
            setSectionToShow("party-editor");
        }
    }, [gen, sectionToShow])

    let availableButtons = [];
    if (!gen) {
        availableButtons.push({
            "text": "Select Gen",
            "section": "gen-selector",
        });
    } else {
        availableButtons.push(
            {
                "text": "Add Pokemon",
                "section": "add-pokemon",
            },
            {
                "text": "Party Editor",
                "section": "party-editor",
            },
        );
    }
    availableButtons.push({
        "text": "Config",
        "section": "config-section",
    });

    if (sectionToShow !== "config-section") {
        setSizingBeingEdited(false);
    }

    let sections = {
        "gen-selector": GenSelector,
        "config-section": ConfigSection,
        "add-pokemon": PokemonAdder,
        "party-editor": PartyEditor,
    };

    let SectionToShow = sections[sectionToShow];

    return (
        <div
            className="sidebar-section"
            style={{
                backgroundColor: changeHexBrightness(backgroundColor, 95)
            }}
        >
            <div className="control-buttons">
                {availableButtons.map((button, i) => {
                    return <button
                        key={i}
                        onClick={() => setSectionToShow(button.section)}
                    >
                        {button.text}
                    </button>
                })}
            </div>
            <div className="displayed-section">
                <SectionToShow />
            </div>
        </div>
    )
};

export default SidebarSection;
