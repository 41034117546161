
const defaultConfig = {
    "background": "#999999",
    "gen": "",
    "partyContainer": 600,
    "backgroundPokeball": 500,
    "partySlot": 175,
    "partySpacing": 5,
    "partyPokeball": 100,
    "partySprite": 275,
    "partyItem": 20,
    "partyText": 20,
    "boxSlot": 80,
    "boxSpacing": 2,
    "boxSprite": 120,
    "boxItem": 20,
    "boxText": 11,
    "boxHeight": 150,
    "boxRowWrap": false,
    "showBigPokeball": true,
    "showSmallPokeballs": true,
};

export default defaultConfig;
