import {useEffect, useState} from 'react';


export const useShiftEnabled = () => {
  const [keyPressed, setKeyPressed] = useState(false);

  const upHandler = ({ key }) => {
    if (key.toLowerCase() === 'shift') {
      setKeyPressed(keyPressed => !keyPressed);
    }
  };

  useEffect(() => {
    window.addEventListener("keyup", upHandler);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener("keyup", upHandler);
    };
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return keyPressed;
}
