import useTrackerContext from "../hooks/useTrackerContext";
import BoxPokemon from "../components/pokemon/BoxPokemon";
import "./box-section.scss";
import React from "react";
import changeHexBrightness from "../utilities/changeHexBrightness";

const BoxSection = () => {

    const {
        boxPokemon,
        boxRowWraps,
        boxTextSizing,
        backgroundColor,
        setBoxDraggedOver,
    } = useTrackerContext();

    const onBoxDragEnter = e => {
        e.preventDefault();
        setBoxDraggedOver(true);
    };

    const onBoxDragExit = e => {
        e.preventDefault();
        setBoxDraggedOver(false);
    };

    const onBoxDrop = e => {
        e.preventDefault();
    };

    return (
        <div
            className={["box-section", boxRowWraps ? "wrap" : "no-wrap"].join(" ")}
            onDrop={onBoxDrop}
            onDragOver={onBoxDragEnter}
            onDragLeave={onBoxDragExit}
            style={{
                backgroundColor: changeHexBrightness(backgroundColor, 110),
                fontSize: `${boxTextSizing}px`
            }}
        >
            {boxPokemon.map((uuid, i) => {
                return <BoxPokemon key={i} uuid={uuid} />
            })}
        </div>
    )
};

export default BoxSection;
