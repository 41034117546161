import useTrackerContext from "../../hooks/useTrackerContext";
import React from "react";

const OtherConfig = () => {

    const {
        boxRowWraps,
        setBoxRowWraps,
        backgroundColor,
        setBackgroundColor,
        showBigPokeball,
        setShowBigPokeball,
        showSmallPokeballs,
        setShowSmallPokeballs,
    } = useTrackerContext();

    const updateBackgroundColor = color => {
        setBackgroundColor(color);
    };

    return (
        <div className="other-config">
            <div className="input-section">
                <label>Background Color</label>
                <input
                    type="color"
                    value={backgroundColor}
                    onChange={e => updateBackgroundColor(e.target.value)}
                />
            </div>
            <div className="input-section">
                <label>Box Row Wraps</label>
                <input
                    type="checkbox"
                    checked={boxRowWraps}
                    onChange={e => setBoxRowWraps(e.target.checked)}
                />
            </div>
            <div className="input-section">
                <label>Show Big Pokeball</label>
                <input
                    type="checkbox"
                    checked={showBigPokeball}
                    onChange={e => setShowBigPokeball(e.target.checked)}
                />
            </div>
            <div className="input-section">
                <label>Show Small Pokeballs</label>
                <input
                    type="checkbox"
                    checked={showSmallPokeballs}
                    onChange={e => setShowSmallPokeballs(e.target.checked)}
                />
            </div>
        </div>
    )
};

export default OtherConfig;
