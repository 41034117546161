import useTrackerContext from "../../hooks/useTrackerContext";
import React, {useState} from "react";
import HoldItem from "./HoldItem";
import Nickname from "./Nickname";
import Level from "./Level";
import "./pokemon.scss";
import Sprite from "./Sprite";
import Shiny from "./Shiny";
import {useShiftEnabled} from '../../hooks/useShiftEnabled';

const Pokemon = ({ uuid }) => {

    const [isDraggedOver, setIsDraggedOver] = useState(false);
    const shiftEnabled = useShiftEnabled('shift');

    const {
        userPokedex,
        deletePokemon,
        backgroundColor,
        pokemonIsInParty,
        setBoxDraggedOver,
        setPartyDraggedOver,
        setPokemonDraggedOver,
        setPokemonBeingDragged,
        performDragAndDropUpdates,
    } = useTrackerContext();

    const pokemon = userPokedex[uuid];

    const onDragStart = e => {
        setPokemonBeingDragged(uuid);
    }

    const onDragEnd = e => {
        e.preventDefault();
        performDragAndDropUpdates();
        setPokemonBeingDragged("");
        setPartyDraggedOver(false);
        setBoxDraggedOver(false);
        setIsDraggedOver(false);
    }

    const onDragEnter = e => {
        e.preventDefault();
        setPokemonDraggedOver(uuid);
        setIsDraggedOver(true);
    }

    const onDragExit = e => {
        e.preventDefault();
        setPokemonDraggedOver("");
        setIsDraggedOver(false);
    }

    const onDrop = e => {
        e.preventDefault();
        setIsDraggedOver(false);
    };

    if (!pokemon) {
        return <span>:(</span>;
    }

    return (
        <div
            className={["pokemon", isDraggedOver ? "dragged-over" : ""].join(" ")}
            style={{
                backgroundColor: pokemonIsInParty(uuid) ? "transparent" : backgroundColor
            }}
            key={uuid}
            draggable
            onDragStart={onDragStart}
            onDragOver={onDragEnter}
            onDragLeave={onDragExit}
            onDragEnd={onDragEnd}
            onDrop={onDrop}
        >
            <Sprite pokemon={pokemon} uuid={uuid} />
            {shiftEnabled
              ?
              <button onClick={() => deletePokemon(uuid)} style={{cursor: 'pointer', zIndex: '99', position: 'absolute'}}>DELETE?</button>
              :
              null
            }
            <Nickname nickname={pokemon.nickname} />
            <Level level={pokemon.level} uuid={uuid} />
            <Shiny shiny={pokemon.isShiny} uuid={uuid} />
            <HoldItem item={pokemon.isHolding} uuid={uuid} />
            {pokemon.isDead && <img src='/assets/backgrounds/dead.gif' alt='dead pokemon' className='crossout' />}
        </div>
    )
};

export default Pokemon;
