import React from "react";

const Nickname = ({ nickname }) => {

    return (
        nickname && <span className="label nickname">{nickname}</span>
    )
}

export default Nickname;
