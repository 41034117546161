import useTrackerContext from "../../hooks/useTrackerContext";
import React from "react";

const HoldItem = ({ item, uuid }) => {

    const getItemFilename = item => {
        return item.toLowerCase().replace(" ", "-");
    };

    const {
        boxItemSizing,
        partyItemSizing,
        pokemonIsInParty,
    } = useTrackerContext();

    const size = pokemonIsInParty(uuid) ? partyItemSizing : boxItemSizing;

    return (
        item && <img
            src={`/assets/items/${getItemFilename(item)}.png`}
            style={{
                width: `${size}px`,
                height: `${size}px`,
            }}
            className="label hold-item"
            alt={item}
            title={item}
            onError={e => {e.target.src = "/assets/items/item.png"}}
        />
    )
};

export default HoldItem;
