import PartyPokemon from "../components/pokemon/PartyPokemon";
import useTrackerContext from "../hooks/useTrackerContext";
import "./display-section.scss";
import React from "react";

const DisplaySection = () => {
    const {
        party,
        setPartyDraggedOver,
        backgroundPokeballSizing,
        partyTextSizing,
        partyWidthSizing,
        sizingBeingEdited,
        showBigPokeball,
    } = useTrackerContext();

    const onDragEnter = e => {
        e.preventDefault();
        setPartyDraggedOver(true);
    };

    const onDragExit = e => {
        e.preventDefault();
        setPartyDraggedOver(false);
    };

    const largePokeballStyles = showBigPokeball
      ? {
        background: "url(/assets/backgrounds/pokeball-lg.png)",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: `${backgroundPokeballSizing}px`,
        imageRendering: "pixelated",
      }
      : {};

    return (
        <div
            className="display-section"
            style={{
                ...largePokeballStyles,
                fontSize: `${partyTextSizing}px`
            }}
        >
            <div
                className={["party", sizingBeingEdited ? "show-borders" : "hide-borders"].join(" ")}
                onDragOver={onDragEnter}
                onDragLeave={onDragExit}
                style={{
                    width: `${partyWidthSizing}px`
                }}
            >
                {party.map((uuid, i) => {
                    return <PartyPokemon key={i} uuid={uuid} />
                })}
            </div>
        </div>
    )
};

export default DisplaySection;
