import React from "react";
import useTrackerContext from "../../hooks/useTrackerContext";

const Shiny = ({ shiny, uuid }) => {

    const {
        boxTextSizing,
        partyTextSizing,
        pokemonIsInParty,
    } = useTrackerContext();

    const size = pokemonIsInParty(uuid) ? partyTextSizing : boxTextSizing;

    return (
        shiny && <span className="shiny" style={{fontSize: `${size * 1.5}px`}}>✨</span>
    )
};

export default Shiny;
