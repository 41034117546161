import useTrackerContext from "../../hooks/useTrackerContext";
import Pokemon from "./Pokemon";
import React from "react";

const PartyPokemon = ({ uuid }) => {

    const {
        partyPokeballSizing,
        partySlotSizing,
        sizingBeingEdited,
        partySlotSpacing,
        showSmallPokeballs,
    } = useTrackerContext();

    const slotFilled = uuid !== undefined;

    const pokeballStyle = showSmallPokeballs
      ? {
        background: "url(/assets/backgrounds/pokeball-sm.png)",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        imageRendering: "pixelated",
        backgroundSize: `${partyPokeballSizing}px`,
      }
      : {};

    return (
        <div
            className="pokemon-container"
            style={{
                ...pokeballStyle,
                margin: `${partySlotSpacing}px`,
            }}
        >
            <div
                className={["pokemon-container-wrapper", sizingBeingEdited ? "show-borders" : "hide-borders"].join(" ")}
                style={{
                    width: `${partySlotSizing}px`,
                    height: `${partySlotSizing}px`,
                }}
            >
                {slotFilled && <Pokemon uuid={uuid} />}
            </div>
        </div>
    )
};

export default PartyPokemon;
