import useTrackerContext from "../../hooks/useTrackerContext";
import React, {useState, useRef, useEffect} from "react";
import save from 'save-file';

const SavingConfig = () => {

    const fileUpload = useRef();
    const [showFileUpload, setShowFileUpload] = useState(false);
    const [showFileUploadConfirm, setShowFileUploadConfirm] = useState(false);
    const [showSaveFileBox, setShowSaveFileBox] = useState(false);
    const [filenameInputValue, setFilenameInputValue] = useState("");
    const [showSaveFileNameInput, setShowSaveFileNameInput] = useState(false);
    const [selectedSaveFile, setSelectedSaveFile] = useState("");
    const [filenameToDisplay, setFilenameToDisplay] = useState("");

    const {
        saveSaveFile,
        getSaveState,
        setSaveState,
        resetTracker,
        saveFileNames,
        addSaveFileName,
        saveCurrentFile,
        createNewSaveFile,
        loadSavedSaveFile,
        deleteSaveFile,
        trackerActiveFileName,
        fileNameAlreadyExists,
        setTrackerActiveFileName,
    } = useTrackerContext();

    useEffect(() => {
        setFilenameToDisplay(trackerActiveFileName);
    },[trackerActiveFileName]);

    const downloadSaveFile = async () => {
        const data = getSaveState();
        const filename = trackerActiveFileName ? trackerActiveFileName : "pokemon-party-tracker";
        await save(JSON.stringify(data, undefined, " "), `${filename}`);
    };

    const loadSaveFile = async () => {
        const fileReader = new FileReader();
        fileReader.readAsText(fileUpload.current.files[0], "json");
        let filename = fileUpload.current.files[0].name;
        fileReader.onload = e => {
            try {
                let json = JSON.parse(e.target.result);
                setSaveState(json);
                while (fileNameAlreadyExists(filename) || !filename) {
                    filename = window.prompt("File name taken, input another");
                }
                addSaveFileName(filename);
                setTrackerActiveFileName(filename);
                saveSaveFile(filename, json);
            } catch (e) {
                alert("Invalid save file");
                const currentState = getSaveState();
                setSaveState(currentState);
            } finally {
                setShowFileUpload(false);
                setShowFileUploadConfirm(false);
            }
        };
    };

    return (
        <div className="other-config">
            {filenameToDisplay && <h3>Current Save: {filenameToDisplay}</h3>}
            <button
                onClick={() => {
                    if (filenameToDisplay) {
                        saveCurrentFile();
                    } else {
                        setShowSaveFileNameInput(true);
                    }
                }}
            >
                Save {filenameToDisplay ? filenameToDisplay : "New File"}
            </button>
            {
                showSaveFileNameInput &&
                    <div>
                        <input
                            type="text"
                            placeholder="Save Name"
                            value={filenameInputValue}
                            onChange={e => setFilenameInputValue(e.target.value)}
                        />
                        <button
                            className="confirm-button"
                            onClick={() => {
                                if (filenameInputValue) {
                                    if (fileNameAlreadyExists(filenameInputValue)) {
                                        alert("File name exists, please enter another");
                                        return;
                                    }
                                    createNewSaveFile(filenameInputValue);
                                    setShowSaveFileNameInput(false);
                                }
                            }}
                        >
                            Confirm
                        </button>
                    </div>
            }
            {
                saveFileNames.length >= 1 &&
                    <div>
                        <button
                            onClick={() => setShowSaveFileBox(true)}
                        >
                            Manage Saves
                        </button>
                        {
                            showSaveFileBox && <div>
                                <select
                                    size={saveFileNames.length + 1}
                                    onChange={e => setSelectedSaveFile(e.target.value)}
                                >
                                    {saveFileNames.map((filename, i) => {
                                        return <option
                                            key={i}
                                            value={filename}
                                        >
                                            {filename}
                                        </option>
                                    })}
                                </select>
                            </div>
                        }
                    </div>
            }
            {
                showSaveFileBox &&
                    <div>
                        <button
                            className="confirm-button"
                            onClick={() => {
                                if (selectedSaveFile) {
                                    if (!fileNameAlreadyExists(selectedSaveFile)) {
                                        return;
                                    }
                                    loadSavedSaveFile(selectedSaveFile);
                                }
                            }}
                        >
                            Load
                        </button>
                        <button
                            className="danger-button"
                            onClick={() => {
                                if (selectedSaveFile) {
                                    if (!fileNameAlreadyExists(selectedSaveFile)) {
                                    return;
                                    }
                                deleteSaveFile(selectedSaveFile);
                                setShowSaveFileBox(false);
                                }
                            }}
                        >
                            Delete
                        </button>
                    </div>
            }
            <button
                onClick={downloadSaveFile}
            >
                Download Save File
            </button>
            <button
                onClick={() => setShowFileUpload(true)}
            >
                Upload Save File
            </button>
            {
                showFileUpload && <div>
                    <label>Upload Save File</label>
                    <input
                        type="file"
                        multiple={false}
                        ref={fileUpload}
                        onChange={e =>
                            e.target.files.length ?
                            setShowFileUploadConfirm(true) :
                                setShowFileUploadConfirm(false)
                        }
                    />
                    {
                        showFileUploadConfirm && <button
                            className="confirm-button"
                            onClick={loadSaveFile}
                        >
                            Load File
                        </button>
                    }
                </div>
            }
            <button
                onClick={() => {
                    if (window.confirm("Are you sure?")) {
                        resetTracker();
                    }
                }}
            >
                Reset Tracker
            </button>
        </div>
    )
};

export default SavingConfig;
