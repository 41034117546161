import {UserPokedexContext} from "../context/UserPokedexContext";
import {useContext} from "react";
import {ConfigContext} from "../context/ConfigContext";
import pokemon from "../data/pokemon";
import {SaveContext} from "../context/SaveContext";

const useTrackerContext = () => {

    const userPokedexContext = useContext(UserPokedexContext);
    const configContext = useContext(ConfigContext);
    const savingContext = useContext(SaveContext);

    const availableGens = Object.keys(pokemon);
    const availablePokemon = (() => {
        let ret = {};
        Object.entries(pokemon).forEach(([gen_num, gen_pkmn]) => {
            if (gen_num <= configContext.gen) {
                ret[gen_num] = gen_pkmn;
            }
        });
        return ret;
    })();

    const saveCurrentFile = () => {
        const currentState = getSaveState();
        savingContext.saveCurrentState(currentState);
    };

    const loadSavedSaveFile = filename => {
        const json = savingContext.getSaveFile(filename);
        setSaveState(json);
        savingContext.setTrackerActiveFileName(filename);
    };

    const saveSaveFile = (filename, json) => {
        savingContext.saveFile(filename, json);
    };

    const getSaveState = () => {
        let allContext = {...configContext, ...userPokedexContext};
        let variables = {};
        let ignoreKeys = [
            "selectingGen",
            "party",
            "partyFull",
            "boxDraggedOver",
            "partyDraggedOver",
            "sizingBeingEdited",
            "pokemonDraggedOver",
            "pokemonBeingDragged",
        ];
        Object.keys(allContext).forEach(key => {
            if (typeof allContext[key] !== "function" && !ignoreKeys.includes(key)) {
                variables[key] = allContext[key];
            }
        });
        return variables;
    };

    const setSaveState = json => {
        const {
            setGen,
            setBoxSlotSizing,
            setBoxSpriteSizing,
            setBackgroundColor,
            setPartySlotSizing,
            setBackgroundPokeballSizing,
            setPartyPokeballSizing,
            setPartySlotSpacing,
            setBoxSlotSpacing,
            setPartySpriteSizing,
            setBoxPokemon,
            setUserPokedex,
            setActiveParty,
            setPartyItemSizing,
            setBoxItemSizing,
            setPartyTextSizing,
            setBoxTextSizing,
            setPartyWidthSizing,
            setBoxHeight,
            setBoxRowWraps,
            setShowBigPokeball,
            setShowSmallPokeballs,
        } = {...userPokedexContext, ...configContext};
        const keysToFunc = {
            "userPokedex": setUserPokedex,
            "activeParty": setActiveParty,
            "boxPokemon": setBoxPokemon,
            "gen": setGen,
            "partyTextSizing": setPartyTextSizing,
            "partyWidthSizing": setPartyWidthSizing,
            "boxTextSizing": setBoxTextSizing,
            "boxSlotSizing": setBoxSlotSizing,
            "boxSpriteSizing": setBoxSpriteSizing,
            "backgroundColor": setBackgroundColor,
            "partySlotSizing": setPartySlotSizing,
            "backgroundPokeballSizing": setBackgroundPokeballSizing,
            "partyPokeballSizing": setPartyPokeballSizing,
            "partySlotSpacing": setPartySlotSpacing,
            "boxSlotSpacing": setBoxSlotSpacing,
            "partySpriteSizing": setPartySpriteSizing,
            "partyItemSizing": setPartyItemSizing,
            "boxItemSizing": setBoxItemSizing,
            "boxHeight": setBoxHeight,
            "boxRowWraps": setBoxRowWraps,
            "showBigPokeball": setShowBigPokeball,
            "showSmallPokeballs": setShowSmallPokeballs,
        };
        Object.keys(keysToFunc).forEach(key => {
            let func = keysToFunc[key];
            let val = json[key]
            if (Array.isArray(val)) {
                func([...val]);
            } else if (typeof val === "object") {
                func({...val});
            } else {
                func(val);
            }
        })
    };

    const createNewSaveFile = filename => {
        savingContext.setTrackerActiveFileName(filename);
        savingContext.addSaveFileName(filename);
        saveSaveFile(filename, getSaveState());
    };

    const deleteSaveFile = filename => {
        if (!window.confirm(`Really delete save '${filename}'?`)) {
            return;
        }
        const {deleteSavedSaveFile, resetConfigs, resetPokedex} = {
            ...savingContext,
            ...configContext,
            ...userPokedexContext,
        };
        deleteSavedSaveFile(filename);
        if (filename === savingContext.trackerActiveFileName) {
            resetConfigs();
            resetPokedex();
        }
    };

    const resetTracker = () => {
        userPokedexContext.setActiveParty([]);
        userPokedexContext.setBoxPokemon([]);
        userPokedexContext.setUserPokedex({});
        configContext.resetConfigs();
        savingContext.setTrackerActiveFileName("");
    };

    return {
        ...userPokedexContext,
        createNewSaveFile,
        loadSavedSaveFile,
        ...savingContext,
        ...configContext,
        availablePokemon,
        saveCurrentFile,
        deleteSaveFile,
        availableGens,
        saveSaveFile,
        getSaveState,
        setSaveState,
        resetTracker,
    }
};

export default useTrackerContext;
