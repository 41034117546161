
const holdItems = [
    "Absorb Bulb",
    "Adamant Orb",
    "Air Balloon",
    "Aloraichium Z",
    "Amulet Coin",
    "Assault Vest",
    "Berry Sweet",
    "Berserk Gene",
    "Big Root",
    "Binding Band",
    "Black Belt",
    "Black Glasses",
    "Black Sludge",
    "Blue Orb",
    "Blue Scarf",
    "Blunder Policy",
    "Bright Powder",
    "Bug Gem",
    "Bug Memory",
    "Buginium Z",
    "Burn Drive",
    "Cell Battery",
    "Charcoal",
    "Chill Drive",
    "Choice Band",
    "Choice Scarf",
    "Choice Specs",
    "Cleanse Tag",
    "Clover Sweet",
    "Damp Rock",
    "Dark Gem",
    "Dark Memory",
    "Darkinium Z",
    "Decidium Z",
    "Deep Sea Scale",
    "Deep Sea Tooth",
    "Destiny Knot",
    "Douse Drive",
    "Draco Plate",
    "Dragon Fang",
    "Dragon Gem",
    "Dragon Memory",
    "Dragonium Z",
    "Dread Plate",
    "Dubious Disc",
    "Earth Plate",
    "Eevium Z",
    "Eject Button",
    "Eject Pack",
    "Electirizer",
    "Electric Gem",
    "Electric Memory",
    "Electric Seed",
    "Electrium Z",
    "Everstone",
    "Eviolite",
    "Exp Share",
    "Expert Belt",
    "Fairium Z",
    "Fairy Gem",
    "Fairy Memory",
    "Fighting Gem",
    "Fighting Memory",
    "Fightinium Z",
    "Fire Gem",
    "Fire Memory",
    "Firium Z",
    "Fist Plate",
    "Flame Orb",
    "Flame Plate",
    "Float Stone",
    "Flower Sweet",
    "Flying Gem",
    "Flying Memory",
    "Flyinium Z",
    "Focus Band",
    "Focus Sash",
    "Full Incense",
    "Ghost Gem",
    "Ghost Memory",
    "Ghostium Z",
    "Grass Gem",
    "Grass Memory",
    "Grassium Z",
    "Grassy Seed",
    "Green Scarf",
    "Grip Claw",
    "Griseous Orb",
    "Ground Gem",
    "Ground Memory",
    "Groundium Z",
    "Hard Stone",
    "Heat Rock",
    "Heavy-Duty Boots",
    "Ice Gem",
    "Ice Memory",
    "Icicle Plate",
    "Icium Z",
    "Icy Rock",
    "Incinium Z",
    "Insect Plate",
    "Iron Ball",
    "Iron Plate",
    "Kings Rock",
    "Kommonium Z",
    "Lagging Tail",
    "Lax Incense",
    "Leek",
    "Leftovers",
    "Life Orb",
    "Light Ball",
    "Light Clay",
    "Love Sweet",
    "Luck Incense",
    "Lucky Egg",
    "Lucky Punch",
    "Luminous Moss",
    "Lunalium Z",
    "Lustrous Orb",
    "Lycanium Z",
    "Macho Brace",
    "Magmarizer",
    "Magnet",
    "Marshadium Z",
    "Meadow Plate",
    "Mental Herb",
    "Metal Coat",
    "Metal Powder",
    "Metronome",
    "Mewnium Z",
    "Mimikium Z",
    "Mind Plate",
    "Miracle Seed",
    "Misty Seed",
    "Muscle Band",
    "Mystic Water",
    "Never-Melt Ice",
    "Normal Gem",
    "Normalium Z",
    "Odd Incense",
    "Pikanium Z",
    "Pikashunium Z",
    "Pink Bow",
    "Pink Scarf",
    "Pixie Plate",
    "Poison Barb",
    "Poison Gem",
    "Poison Memory",
    "Poisonium Z",
    "Polkadot Bow",
    "Power Anklet",
    "Power Band",
    "Power Belt",
    "Power Bracer",
    "Power Herb",
    "Power Lens",
    "Power Weight",
    "Primarium Z",
    "Protective Pads",
    "Protector",
    "Psychic Gem",
    "Psychic Memory",
    "Psychic Seed",
    "Psychium Z",
    "Pure Incense",
    "Quick Claw",
    "Quick Powder",
    "Razor Claw",
    "Razor Fang",
    "Reaper Cloth",
    "Red Card",
    "Red Orb",
    "Red Scarf",
    "Ribbon Sweet",
    "Ring Target",
    "Rock Gem",
    "Rock Incense",
    "Rock Memory",
    "Rockium Z",
    "Rocky Helmet",
    "Room Service",
    "Rose Incense",
    "Rusted Shield",
    "Rusted Sword",
    "Safety Goggles",
    "Scope Lens",
    "Sea Incense",
    "Sharp Beak",
    "Shed Shell",
    "Shell Bell",
    "Shock Drive",
    "Silk Scarf",
    "Silver Powder",
    "Sky Plate",
    "Smoke Ball",
    "Smooth Rock",
    "Snorlium Z",
    "Snowball",
    "Soft Sand",
    "Solganium Z",
    "Soothe Bell",
    "Soul Dew",
    "Spell Tag",
    "Splash Plate",
    "Spooky Plate",
    "Star Sweet",
    "Steel Gem",
    "Steel Memory",
    "Steelium Z",
    "Sticky Barb",
    "Stone Plate",
    "Strawberry Sweet",
    "Tapunium Z",
    "Terrain Extender",
    "Thick Club",
    "Throat Spray",
    "Toxic Orb",
    "Toxic Plate",
    "Twisted Spoon",
    "Ultranecrozium Z",
    "Utility Umbrella",
    "Water Gem",
    "Water Memory",
    "Waterium Z",
    "Wave Incense",
    "Weakness Policy",
    "White Herb",
    "Wide Lens",
    "Wise Glasses",
    "Yellow Scarf",
    "Zap Plate",
    "Zoom Lens",
];

export default holdItems;
