import SizingConfig from "./SizingConfig";
import SavingConfig from "./SavingConfig";
import OtherConfig from "./OtherConfig";
import React, {useState} from "react";
import "./config.scss";
import useTrackerContext from "../../hooks/useTrackerContext";

const ConfigSection = () => {

    const [showSizingConfig, setShowSizingConfig] = useState(false);
    const [showSavingConfig, setShowSavingConfig] = useState(false);
    const [showOtherConfig, setShowOtherConfig] = useState(false);

    const {
        setSizingBeingEdited,
    } = useTrackerContext();

    let availableButtons = [
        {
            "text": "Sizes",
            "func": setShowSizingConfig,
        },
        {
            "text": "Saving",
            "func": setShowSavingConfig,
        },
        {
            "text": "Other",
            "func": setShowOtherConfig,
        },
    ];

    const setSelectedButtonToTrue = buttonIndex => {
        availableButtons.forEach(({func}, i) => {
            func(buttonIndex === i);
        });
        if (buttonIndex === 0) {
            setSizingBeingEdited(true);
        } else {
            setSizingBeingEdited(false);
        }
    };

    return (
        <div className="config-section">
            <div className="buttons">
                {availableButtons.map((button, i) => {
                    return <button
                        key={i}
                        onClick={() => setSelectedButtonToTrue(i)}
                    >
                        {button.text}
                    </button>
                })}
            </div>
            {showSizingConfig && <SizingConfig />}
            {showSavingConfig && <SavingConfig />}
            {showOtherConfig && <OtherConfig />}
        </div>
    )
};

export default ConfigSection;
