import React from "react";
import useTrackerContext from "../../hooks/useTrackerContext";

const Level = ({ level, uuid }) => {

    const {setPokemonLevel} = useTrackerContext();

    const updatePokemonLevel = (e, uuid, newLevel) => {
        e.preventDefault();
        setPokemonLevel(uuid, newLevel);
    };

    let parsedLevel = typeof level === "string" ? parseInt(level) : level;

    parsedLevel = parsedLevel <= 1 ? 1 :
      parsedLevel >= 99 ? 99 :
        parsedLevel;

    return (
        level && <span
          className="label level"
          title={`Level ${parsedLevel}`}
          onClick={e => updatePokemonLevel(e, uuid, parsedLevel === 99 ? 99 : parsedLevel + 1)}
          onContextMenu={e => updatePokemonLevel(e, uuid, parsedLevel === 1 ? 1 : parsedLevel - 1)}
        >
            Lv. {parsedLevel}
        </span>
    )
}

export default Level;
