import useTrackerContext from "../../hooks/useTrackerContext";
import React, {useState} from "react";
import "./gen-selector.scss";

const GenSelector = () => {
    const [selectedGen, setSelectedGen] = useState("0");
    const {
        setGen,
        availableGens,
        setSelectingGen,
    } = useTrackerContext();

    const setGenContext = () => {
        setGen(selectedGen);
        setSelectingGen(false);
    };

    return (
        <div className="gen-selector">
            <select onChange={e => setSelectedGen(e.target.value)}>
                <option value="0">Select Gen</option>
                {availableGens.map((gen, i) => <option key={i} value={gen}>Gen {gen}</option>)}
            </select>
            <button onClick={setGenContext} disabled={selectedGen === "0"}>Confirm Gen</button>
        </div>
    )
};

export default GenSelector;

