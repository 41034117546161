import useTrackerContext from "../../hooks/useTrackerContext";
import getGenOfPokemon from "../../utilities/getGenOfPokemon";
import React from "react";

const Sprite = ({ pokemon, uuid }) => {

    const {
        boxSpriteSizing,
        pokemonIsInParty,
        partySpriteSizing,
    } = useTrackerContext();

    let name = pokemon.pokemon;

    let size = pokemonIsInParty(uuid) ? partySpriteSizing : boxSpriteSizing;
    const pokemonGen = getGenOfPokemon(name);
    size = pokemonGen >= 7 ? size * .5 : size;
    size = pokemon.spriteSize !== undefined ? pokemon.spriteSize * size : size;

    if (name === "Type: Null") name = "TypeNull";
    else if (name === "Sirfetch'd") name = "Sirfetchd"

    return (
        <img
            className="sprite"
            alt={`${name} Sprite`}
            title={name}
            src={`/assets/pokemon/${name}.png`}
            style={{
                width: `${size}px`,
                height: `${size}px`,
            }}
            onError={e => e.target.src = "/assets/pokemon/0.png"}
        />
    )
}

export default Sprite;
