import ConfigurationSidebar from "./SidebarSection";
import DisplaySection from "./DisplaySection";
import BoxSection from "./BoxSection";
import React from "react";

const TrackerLayout = () => {

    return (
        <div className="pokemon-party-tracker">
            <ConfigurationSidebar />
            <DisplaySection />
            <BoxSection />
        </div>
    )
};

export default TrackerLayout;
