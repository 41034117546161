import "./pokemon-adder.scss";
import React, {useState} from "react";
import useTrackerContext from "../../hooks/useTrackerContext";
import holdItems from "../../data/hold-items";

const PokemonAdder = () => {

    const [destination, setDestination] = useState("");
    const [isHolding, setIsHolding] = useState("");
    const [isShiny, setIsShiny] = useState(false);
    const [nickname, setNickname] = useState("");
    const [pokemon, setPokemon] = useState("");
    const [level, setLevel] = useState(10);
    const [isDead, setIsDead] = useState(false);

    const addPokemon = () => {
        if (!pokemon) return;
        addPokemonToPokedex({
            pokemon,
            level,
            nickname,
            isShiny,
            isDead,
            isHolding,
        }, destination);
        resetInputs();
    };

    const resetInputs = () => {
        setDestination("");
        setIsHolding("");
        setIsShiny(false);
        setNickname("");
        setPokemon("");
        setLevel(10);
    };

    const {
        partyFull,
        availablePokemon,
        addPokemonToPokedex,
    } = useTrackerContext();

    return (
        <div className="new-pokemon">
            <div className="pokemon-attribute">
                <label htmlFor="pokemon-select">Pokemon</label>
                <select
                    name="pokemon-select"
                    onChange={e => setPokemon(e.target.value)}
                    value={pokemon}
                >
                    <option value="">Select Pokemon</option>
                    {Object.entries(availablePokemon).map(([gen_num, gen_pkmn], i) => {
                        return <optgroup key={i} label={`Gen ${gen_num}`}>
                            {gen_pkmn.map((pkmn, j) => {
                                return <option key={j} value={pkmn}>
                                    {pkmn}
                                </option>
                            })}
                        </optgroup>
                    })}
                </select>
            </div>
            <div className="pokemon-attribute">
                <label htmlFor="level-select">Level</label>
                <input
                    name="level-select"
                    onChange={e => setLevel(parseInt(e.target.value))}
                    value={level}
                    type="number"
                    min={1}
                    max={100}
                />
            </div>
            <div className="pokemon-attribute">
                <label htmlFor="nickname">Nickname</label>
                <input
                    name="nickname"
                    onChange={e => setNickname(e.target.value)}
                    value={nickname}
                    type="text"
                    maxLength={10}
                />
            </div>
            <div className="pokemon-attribute">
                <label htmlFor="pokemon-destination">Destination</label>
                <div className="checkboxes" onChange={e => setDestination(e.target.value)}>
                    <span className="checkbox-and-label">
                        <input
                            type="radio"
                            value="party"
                            disabled={partyFull}
                            name="pokemon-destination"
                        /> Party
                    </span>
                    <span className="checkbox-and-label">
                        <input
                            type="radio"
                            value="box"
                            defaultChecked={partyFull}
                            name="pokemon-destination"
                        /> Box
                    </span>
                </div>
            </div>
            <div className="pokemon-attribute is-shiny">
                <label htmlFor="is-shiny">Shiny</label>
                <input name="is-shiny" type="checkbox" checked={isShiny} onChange={e => setIsShiny(e.target.checked)} />
            </div>
            <div className="pokemon-attribute is-dead">
                <label htmlFor="is-dead">Dead</label>
                <input name="is-dead" type="checkbox" checked={isDead} onChange={e => setIsDead(e.target.checked)} />
            </div>
            <div className="pokemon-attribute">
                <label htmlFor="item-held">Item Holding</label>
                <select
                    name="item-held"
                    onChange={e => setIsHolding(e.target.value)}
                    value={isHolding}
                >
                    <option value="">Select Item</option>
                    {holdItems.map((item, i) => {
                        return <option key={i} value={item}>
                            {item}
                        </option>
                    })}
                </select>
            </div>
            <div className="buttons">
                <button
                    className="confirm-button"
                    onClick={addPokemon}
                >
                    Confirm
                </button>
                <button
                    className="danger-button"
                    onClick={resetInputs}
                >
                    Cancel
                </button>
            </div>
        </div>
    )
};

export default PokemonAdder;
